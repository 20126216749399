import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/d0_white.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/tg_white.png';
import navIcon3 from '../assets/img/nav-icon2.svg';
import navIcon4 from '../assets/img/dextools_white2.png';
import navIcon5 from '../assets/img/opensea2.png';
import logo from '../assets/img/logo.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";


export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
              <div className="red">
              <img src={logo} className="logo" alt="Logo" />

                  <p>
                  <b>Dog Wif Hat 404 is the first collection of ERC404 tokens dedicated to the viral Dog Wif Hat meme. The ERC404 standard enables persistent liquidity and semi-fungibility for ethereum NFTs. </b><br />
                  </p>
                  <div>
                    <p>
                      <b>XXXXXXXXXXXXXXXXXXXXXXXXX</b>
                    </p>
                  </div>
                  <div className="social-icon">
                    <a href="https://twitter.com/WifERC404"><img src={navIcon1} alt="" /></a>
                    <a href="https://t.me/WifERC404"><img src={navIcon2} alt="" /></a>
                    <a href="https://etherscan.com"><img src={navIcon3} alt="" /></a>
                    <a href="https://dexscreener.com"><img src={navIcon4} alt="" /></a>
                    <a href="https://opensea.io"><img src={navIcon5} alt="" /></a>
                </div>
                  </div>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>


      </Container>
    </section>
  )
}
