import React from "react";
import { motion } from "framer-motion";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from "./components/Banner";
import { Memes } from "./components/Memes";


export default function App() {
  return (
    <div className="App">
      <Banner />
      <Memes/>
    </div>
  );
}
