import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg0 from "../assets/img/d0.jpg";
import projImg1 from "../assets/img/d1.jpg";
import projImg2 from "../assets/img/d2.jpg";
import projImg3 from "../assets/img/d3.jpg";
import projImg4 from "../assets/img/d4.jpg";
import projImg5 from "../assets/img/d5.jpg";
import projImg6 from "../assets/img/d6.jpg";
import projImg7 from "../assets/img/d7.jpg";
import projImg8 from "../assets/img/d8.jpg";
import projImg9 from "../assets/img/d9.jpg";
import projImg10 from "../assets/img/d10.jpg";
import projImg11 from "../assets/img/d11.jpg";
import projImg12 from "../assets/img/d12.jpg";
import projImg13 from "../assets/img/d13.jpg";



import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Memes = () => {

  const memes = [
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg0,
    },{
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg1,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg2,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg3,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg4,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg5,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg6,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg7,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg8,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg9,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg10,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg11,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg12,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg13,
    }
  ];

  return (
    <section className="meme" id="memes">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>404s Available</h2>
                <p></p>
                <Row>
                        {
                          memes.map((meme, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...meme}
                                />
                            )
                          })
                        }
                      </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

